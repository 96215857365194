import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import CheckoutSuccess from "../../../features/quotes-funnel/ui/pages/checkout-success/CheckoutSuccess";
import { PageId } from "../../../settings/pages";

const Page = (): ReactElement => {
  return <CheckoutSuccess />;
};

export default withFunnelWrapper(Page, "pg-checkout-success", PageId.checkoutSuccess, false);

export const query = graphql`
  query CheckoutSuccessPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
