import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import React, { ReactElement } from "react";

import ConnectionLost from "../../../../../images/pages/leads/connection-lost.svg";
import Loader from "../../../../../molecules/loader/Loader";
import PageTitle from "../../../../../molecules/page-title/PageTitle";
import { Events } from "../../../../../utils/analytics";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import { useCheckoutSuccess } from "../../../application/hooks/useCheckoutSuccess";
import * as styles from "./CheckoutSuccess.module.scss";

const CheckoutSuccess = (): ReactElement => {
  const { t } = useTranslation();
  const { errorOnSubscriptionCreation } = useCheckoutSuccess();

  useTrackPageViewed(Events.CHECKOUT_SUCCESS_VIEWED_BROWSER);

  return errorOnSubscriptionCreation ? (
    <section className={styles.errorOnSubscriptionCreationWrapper}>
      <ConnectionLost />
      <PageTitle
        title={t("pg_checkout_success.create_subscription_error.title")}
        subtitle={parse(t("pg_checkout_success.create_subscription_error.subtitle"))}
        adoptionClassName={styles.errorOnSubscriptionCreationHeader}
      />
    </section>
  ) : (
    <Loader title={t("pg_checkout_success.title")} />
  );
};

export default CheckoutSuccess;
