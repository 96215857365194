import { navigate } from "gatsby";
import { useCallback, useEffect, useState } from "react";

import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../../utils/browser-features";
import { handleCreateSubscriptionErrors } from "../../utils/error-utils/catch-error-handlers";
import { useCreateSubscription } from "../checkout-use-cases";
import { usePromotionCode } from "../promotion-code-use-cases";

export const useCheckoutSuccess = (): { errorOnSubscriptionCreation: boolean } => {
  const result = useResult();
  const createSubscription = useCreateSubscription();
  const promotionCode = usePromotionCode();
  const promotionCodeId = promotionCode?.id;
  const [errorOnSubscriptionCreation, setErrorOnSubscriptionCreation] = useState(false);

  const _createSubscription = useCallback(
    async (resultUuid: string, setupIntent: string) => {
      try {
        await createSubscription(resultUuid, setupIntent, promotionCodeId);

        void navigate(`/results/${resultUuid}/binding/`);
      } catch (error) {
        const e = error as Error;
        const errorMessage = e.message;

        handleCreateSubscriptionErrors(e, errorMessage);
        setErrorOnSubscriptionCreation(true);
        track(Events.ERROR_OCCURRED, {
          error: "Error on subscription creation",
          message: errorMessage,
        });
      }
    },
    [createSubscription, promotionCodeId]
  );

  useEffect(() => {
    if (!result || result.isAlreadyPaid) {
      return;
    }

    const currentURLSearchParams = getCurrentURLSearchParams();
    const setupIntentId = currentURLSearchParams?.get("setup_intent");

    if (setupIntentId) {
      void _createSubscription(result.uuid, setupIntentId);
    } else {
      void navigate(`/results/${result.uuid}/binding/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { errorOnSubscriptionCreation };
};
